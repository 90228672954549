<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .offenen-positionen-all {
    .header-intro-generel-box {
      background-position: center center !important;
    }

    .whitespace-karriereweiterbildung {
      padding: 140px 0;
      @media (max-width: $width-lg) {
        padding: 50px 0;
      }
    }
    .top-box {
      position: relative;
      .img-v2 {
        position: relative;
        left: -150px;
        @media (max-width: $width-lg) {
          left: 0;
        }
      }
      .intro-txt {
        @media (max-width: $width-lg) {
          padding: 25px 0;
        }
      }
    }
    .jobs-firma-outer {
      .btn-reset-filter-firma {
        display: block;
        position: relative;
        top: -40px;
        text-align: center;

        span {
          display: inline-block;
          padding: 5px 0px;
          letter-spacing: 2px;
          border-bottom: 1px solid #000;
          cursor: pointer;
          &:hover {
            //opacity: .7;
            color: $violet;
            border-bottom: 1px solid $violet;
          }
        }
      }
      .firma-btn {
        color: #fff;
        font-family: 'Helvetica Neue LT W05 65 Medium';
        letter-spacing: 2px;
        padding: 10px 15px;
        margin-bottom: 5px;
        cursor: pointer;
        @media (max-width: $width-md) {
          font-size: 90%;
          padding: 5px 10px;
        }
        @media (max-width: $width-sm) {
          text-align: center;
        }
        &:hover {
          opacity: 0.7;
        }
      }
      .firma-btn-active {
        background: $violet;
        display: inline-block;
        @media (max-width: $width-sm) {
          text-align: center;
          display: block;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .search-bar-outer {
      background: #ebebeb;
      padding: 20px;
      position: relative;
      top: -42px;
      .input-group {
        input {
          border-radius: 0;
          outline: none;
          padding: 10px 15px;
          border: 1px solid #fff;
          &:focus,
          &:active {
            box-shadow: none;
            border: 1px solid $violet;
          }
        }
        .input-group-text {
          background: #fff;
          position: relative;
          left: -1px;
          border: 1px solid #fff;
          border-left: 0;
          padding: 0.375rem 1rem;
          img {
            width: 20px;
          }
        }
        ::-webkit-input-placeholder {
          /* Edge */
          letter-spacing: 2px;
          color: #a0a0a0;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          letter-spacing: 2px;
          color: #a0a0a0;
        }
        ::placeholder {
          letter-spacing: 2px;
          color: #a0a0a0;
        }
      }
    }
    .job-list-outer {
      .job-inner-header {
        padding: 10px 10px;
        @media (max-width: $width-lg) {
          display: none;
        }
        [class*='col-'] {
          // border:1px solid blue;
        }
        .std-format {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size: 180%;
          letter-spacing: 1px;
          img {
            width: 40px;
            display: inline-block;
            position: relative;
            top: -2px;
            left: 5px;
            display: none;
          }
        }
        .title {
          padding-left: 15px;
        }
      }
      .job-header-select {
        display: none;
        @media (max-width: $width-lg) {
          display: block;
          background: #ebebeb;
          padding: 15px;
          margin-bottom: 25px;

          select {
            border-color: #fff;
            &:focus {
              box-shadow: none;
              border-color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="offenen-positionen-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  :headerImageText="$t('General.offene-positionen')" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb">
                <span>{{ $t('General.karriere') }}</span>
              </div>
              <h1>{{ $t('General.offene-positionen') }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7"></div>
      </div>
    </div>

    <div class="container top-box">
      <div class="row justify-content-center align-self-center padding-t-s padding-b-m">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/karriere/offenen-positionen-v1.jpg" alt="Traumjob gesucht?" class="img-fluid" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <p class="intro-txt text-center font-helv-medium padding-tb-s">{{ $t('offene-positionen.intro-txt') }}</p>
          <img src="@/assets/img/karriere/offenen-positionen-v2.jpg" alt="Traumjob gesucht?" class="img-fluid img-v2" v-inview="['move-in-from-right']" />
        </div>
      </div>
    </div>

    <!-- TEMPLATE -->

    <div class="bg-violet padding-tb-m" style="display:none;">
      <div class="container">
        <div class="row jobs-firma-outer">
          <!-- FILTER Zurücksetzen BUTTON -->
          <div class="col-lg-12">
            <div class="btn-reset-filter-firma"><span>Filter zurücksetzen</span></div>
          </div>
          <!-- FILTER Zurücksetzen BUTTON -->
          <!-- TEMPLATE -->
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Antenne Kärnten</div>
          </div>
          <!-- TEMPLATE ENDE -->
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Antenne Steiermark</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Anzeigen und Marketing Kleine Zeitung</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">autoPro24</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Ballguide</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Call & Mail</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn firma-btn-active">Content Performance Group</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Die Furche</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Druck Carinthia</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Druck STYRIA</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">FIDES</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Kleine Zeitung</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">missMEDIA</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Redmail</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Styria Call & Mail</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Styria Digital Services</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="firma-btn">Styria IT Solutions</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="search-bar-outer">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="SUCHBEGRIFF" v-model="filter" />
              <span class="input-group-text"><img src="@/assets/img/header-suche-icon-v2.svg" alt="Kontakt"/></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container job-list-outer">
      <!-- <div class="job-inner-header">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <div class="std-format title">{{$t('offene-positionen.stelle')}} <img src="@/assets/img/icon-filter.svg" alt="Filter" /></div>
          </div>
          <div class="col-lg-4 col-md-6 align-self-center ">
            <div class="std-format location">{{$t('offene-positionen.ort')}} <img src="@/assets/img/icon-filter.svg" alt="Filter" /></div>
          </div>
          <div class="col-lg-2 col-md-6 align-self-center">
            <div class="std-format date">{{$t('offene-positionen.datum')}}</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="job-header-select">
        <select class="form-select form-select-lg" aria-label=".form-select-lg example">
          <option selected>{{$t('offene-positionen.filtern-nach')}}</option>
          <option value="1">{{$t('offene-positionen.stelle')}}</option>
          <option value="2">{{$t('offene-positionen.ort')}}</option>
          <option value="3">{{$t('offene-positionen.datum')}}</option>
        </select>
      </div> -->
      <!-- TEMPLATE-->
      <KarriereEntry :job="job" :key="job.id" v-for="job of filteredjobs" />
      <!-- TEMPLATE ENDE -->
    </div>
    <div class="container">
      <div class="row justify-content-center margin-t-l">
        <div class="col-lg-10">
          <div class="highlight-txt-end text-center padding-t-l">
            <p>{{ $t('offene-positionen.talentepool-info-txt') }}</p>
            <div class="btn-std">
              <router-link :to="{ name: 'talentepool' }"
                ><span class="txt">{{ $t('offene-positionen.btn-talentepool') }}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import { mdiChevronUp } from '@mdi/js';
import { useStore } from 'vuex';

import KarriereEntry from '../../../components/Frontend/KarriereEntry.vue';

type CareerRecord = {
  id: number;
  name: string;
  location: string;
  company: string;
  link: string;
  details: string;
  createdat: Date;
};

export default defineComponent({
  meta: {
    de: {
      title: 'Offene Stellen | Styria Media Group',
      keywords: ['job', 'offene stellen', 'stellenbörse', 'styria', 'mitarbeiter werden', 'karriere'],
      description: 'Hier geht es zu unseren offenen Positionen. We are as good as our people.',
      image: require('@/assets/img/og/karriere.png'),
    },
    en: {
      title: 'Job offers | Styria Media Group',
      keywords: ['job', 'vacancies', 'job exchange', 'styria', 'become an employee', 'career'],
      description: 'Vacancies at STYRIA. We are as good as our people',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    KarriereEntry,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    const store = useStore();
    const jobs = ref<Array<CareerRecord>>([]);
    const filter = ref('');

    store.dispatch('fetchCareers').then((res: Array<CareerRecord>) => {
      jobs.value = res.sort((a: CareerRecord, b: CareerRecord) => {
        if (a.createdat > b.createdat) return -1;
        if (a.createdat < b.createdat) return 1;
        return 0;
      });
    });

    const filteredjobs = computed(() => jobs.value.filter((item: CareerRecord) => item.company?.toLowerCase().includes(filter.value.toLowerCase()) || item.name.toLowerCase().includes(filter.value.toLowerCase()) || item.location.toLowerCase().includes(filter.value.toLowerCase()) || item.details.toLowerCase().includes(filter.value.toLowerCase())));

    return {
      jobs,
      filteredjobs,
      filter,
      mdiChevronUp,
    };
  },
});
</script>
