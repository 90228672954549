<style lang="scss">
#frontend {
  .job-inner {
    //border:1px solid red;
    position: relative;
    padding: 20px 10px;
    transition: $transition-std;
    letter-spacing: 1px;
    [class*='col-'] {
      // border:1px solid blue;
    }
    &:nth-child(odd) {
      background: #e6e6e6;
    }
    @media (max-width: 992px) {
      text-align: center;
    }
    .title {
      font-family: 'Helvetica Neue LT W05 65 Medium';
      font-size: 120%;
      padding-left: 15px;

      @media (max-width: 992px) {
        padding-left: 0;
      }
    }
    .details {
      margin-top: 5px;
      padding-left: 15px;
      @media (max-width: 992px) {
        margin-bottom: 5px;
        padding-left: 0;
      }
    }
    .location {
    }
    .company {
      font-family: 'Helvetica Neue LT W05 65 Medium';
    }
    .date {
    }

    a {
    }
    &:hover {
      background: $wine-red;
      color: #fff;
    }
  }
}
</style>

<template>
  <div class="job-inner">
    <div class="row">
      <div class="col-lg-6 align-self-center">
        <div class="title">{{ job.name }}</div>
        <div class="details">{{ job.details }}</div>
      </div>
      <div class="col-lg-4 col-md-6 align-self-center ">
        <div class="company" v-if="job.company">{{ job.company }}</div>
        <div class="location">{{ job.location }}</div>
      </div>
      <div class="col-lg-2 col-md-6 align-self-center">
        <div class="date">{{ date }}</div>
      </div>
    </div>
    <a :href="entrylink" target="_blank" class="stretched-link"></a>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { formatDate } from '@/i18n';

type CareerRecord = {
  id: number;
  name: string;
  location: string;
  company: string;
  link: string;
  details: string;
  createdat: Date;
};

export default defineComponent({
  props: {
    job: { type: Object as PropType<CareerRecord>, required: true },
  },
  setup(props) {
    const date = formatDate(props.job.createdat, 'DD.MM.YYYY');
    const entrylink = computed(() => {
      if (props.job.link) {
        if (!props.job.link.startsWith('http')) {
          return `https://karriere.styria.com${props.job.link}`;
        } else {
          return props.job.link;
        }
      }
      return '#';
    });

    return {
      date,
      entrylink,
    };
  },
});
</script>
