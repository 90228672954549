
import { computed, defineComponent, PropType } from 'vue';
import { formatDate } from '@/i18n';

type CareerRecord = {
  id: number;
  name: string;
  location: string;
  company: string;
  link: string;
  details: string;
  createdat: Date;
};

export default defineComponent({
  props: {
    job: { type: Object as PropType<CareerRecord>, required: true },
  },
  setup(props) {
    const date = formatDate(props.job.createdat, 'DD.MM.YYYY');
    const entrylink = computed(() => {
      if (props.job.link) {
        if (!props.job.link.startsWith('http')) {
          return `https://karriere.styria.com${props.job.link}`;
        } else {
          return props.job.link;
        }
      }
      return '#';
    });

    return {
      date,
      entrylink,
    };
  },
});
