
import { defineComponent, ref, computed } from 'vue';
import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import { mdiChevronUp } from '@mdi/js';
import { useStore } from 'vuex';

import KarriereEntry from '../../../components/Frontend/KarriereEntry.vue';

type CareerRecord = {
  id: number;
  name: string;
  location: string;
  company: string;
  link: string;
  details: string;
  createdat: Date;
};

export default defineComponent({
  meta: {
    de: {
      title: 'Offene Stellen | Styria Media Group',
      keywords: ['job', 'offene stellen', 'stellenbörse', 'styria', 'mitarbeiter werden', 'karriere'],
      description: 'Hier geht es zu unseren offenen Positionen. We are as good as our people.',
      image: require('@/assets/img/og/karriere.png'),
    },
    en: {
      title: 'Job offers | Styria Media Group',
      keywords: ['job', 'vacancies', 'job exchange', 'styria', 'become an employee', 'career'],
      description: 'Vacancies at STYRIA. We are as good as our people',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    KarriereEntry,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    const store = useStore();
    const jobs = ref<Array<CareerRecord>>([]);
    const filter = ref('');

    store.dispatch('fetchCareers').then((res: Array<CareerRecord>) => {
      jobs.value = res.sort((a: CareerRecord, b: CareerRecord) => {
        if (a.createdat > b.createdat) return -1;
        if (a.createdat < b.createdat) return 1;
        return 0;
      });
    });

    const filteredjobs = computed(() => jobs.value.filter((item: CareerRecord) => item.company?.toLowerCase().includes(filter.value.toLowerCase()) || item.name.toLowerCase().includes(filter.value.toLowerCase()) || item.location.toLowerCase().includes(filter.value.toLowerCase()) || item.details.toLowerCase().includes(filter.value.toLowerCase())));

    return {
      jobs,
      filteredjobs,
      filter,
      mdiChevronUp,
    };
  },
});
